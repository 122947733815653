<template>
  <div class="main">
    <KTCodePreview :title="title">
      <template v-slot:preview>
        <b-row>
          <b-col>
            <b-form-group>
              <label>
                Tên giỏ hàng
                <b class="text-danger">*</b>
              </label>
              <b-form-input
                size="sm"
                type="text"
                placeholder="Nhập tên giỏ hàng"
                v-model="mainModel.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="required-control">
              <label>Ngày bắt đầu:</label>
              <date-picker
                placeholder="Từ ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="mainModel.startDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label>Đến ngày</label>
              <date-picker
                placeholder="Đến ngày"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="mainModel.endDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label>Trạng thái:</label>
              <b-form-select
                size="sm"
                v-model="mainModel.status"
                :options="listStatus"
                required
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="search-product">
          <div class="left">
            <b-form-group
              label-class="label-input"
              label="Tìm sản phẩm:"
            >
              <Autosuggest
                :model="searchProduct"
                :suggestions="filteredProducts"
                suggestionName="suggestionName"
                placeholder="mã IMEI"
                :limit="LIMIT_SEARCH_ITEM"
                @select="onSelectedProduct"
                @change="onInputProduct"
              />
            </b-form-group>
          </div>
          <div class="right">
            <b-button
              @click="importExcel"
              size="sm"
              variant="secondary"
              class="font-weight-bolder mt-2"
            >
              <i
                style="font-size: 1rem"
                class="far fa-file-excel"
              ></i>Nhập SP từ Excel
            </b-button>
          </div>
        </div>

        <div
          class="list-product"
          v-if="mainModel.products.length"
        >
          <h5 class="title">Danh sách sản phẩm</h5>
          <div class="product-table">
            <b-table
              class="table-bordered table-hover col-md-12"
              :fields="tableFields"
              :items="mainModel.products"
            >
              <template v-slot:cell(actions)="row">
                <v-icon
                  small
                  class="text-danger text-center"
                  @click="deleteFromCart(row.item)"
                  v-b-tooltip
                  title="Xóa"
                >mdi-delete</v-icon>
              </template>
            </b-table>
          </div>
        </div>

        <div class="mb-5 mt-5 submit-container">
          <b-button
            class="cta-btn"
            size="sm"
            variant="primary"
            @click="save"
          >
            Lưu
          </b-button>
          <router-link
            to="/priority-cart"
            tag="button"
          >
            <b-button
              class="cta-btn ml-2"
              size="sm"
              variant="secondary"
            >Hủy</b-button>
          </router-link>
        </div>
      </template>
    </KTCodePreview>
    <ImportExcelModal />
  </div>
</template>

<script>
const NOTE_ROWS = 5;
const NOTE_MAX_ROWS = 5;
const LIMIT_SEARCH_ITEM = 10;
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile, convertPrice } from '@/utils/common';
import ImportExcelModal from '@/view/components/priority-cart/ImportExcelModal.vue';
import { EventBus } from '@/core/services/event-bus';
import moment from 'moment';
import { reject, find } from 'lodash';
import { TIME_TRIGGER } from '@/utils/constants';
import debounce from 'debounce';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
    ImportExcelModal,
  },
  data() {
    return {
      isNew: true,
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Tên sản phẩm',
          sortable: false,
        },
        {
          key: 'code',
          label: 'Mã sản phẩm',
          sortable: false,
        },
        {
          key: 'imei',
          label: 'Mã IMEI',
          sortable: false,
        },
        {
          key: 'price',
          label: 'Giá sản phẩm',
          sortable: false,
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
        },
      ],
      title: 'Thông tin giỏ hàng ưu tiên',
      searchProduct: '',
      optionsProduct: [],
      filteredProducts: [],
      LIMIT_SEARCH_ITEM,
      NOTE_ROWS,
      NOTE_MAX_ROWS,
      dpConfigs: DP_CONFIG,
      mainModel: {
        id: null,
        name: null,
        startDate: moment(),
        endDate: moment().add(15, 'days').format('DD/MM/YYYY'),
        status: 1,
        products: [],
      },
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Không hoạt động',
        },
      ],
    };
  },
  created() {
    this.mainModel.id = this.$route.query.id;
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Danh sách giỏ hàng ưu tiên',
        route: '/priority-cart',
      },
      { title: this.title },
    ]);
    EventBus.$on('importPriorityCart', this.handleExcelData);
  },
  destroyed() {
    EventBus.$off('importPriorityCart', this.handleExcelData);
  },
  methods: {
    fetchProductImei() {
      ApiService.get(
        `productSearch/search-imei?imeiCode=${this.searchProduct}`,
      ).then(({ data }) => {
        this.optionsProduct = [];
        const products = data.data;
        products.map((product) => {
          this.optionsProduct.push({
            ...product,
            suggestionName: `${product.productName} (IMEI: ${product.imeiCode})`,
          });
        });
        this.filteredProducts = [...this.optionsProduct];
      });
    },
    fetchData() {
      if (!this.mainModel.id) return;
      ApiService.query(`priority-cart/${this.mainModel.id}`).then(
        (response) => {
          const { data } = response.data;
          this.mainModel.name = data.name;
          this.mainModel.startDate = data.startDate
            ? moment(data.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null;
          this.mainModel.endDate = data.endDate
            ? moment(data.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null;
          this.mainModel.status = data.status;
          this.mainModel.products = [...data.productImeiStocks];
        },
      );
    },
    handleExcelData(data) {
      if (!data?.length) {
        return;
      }
      data.forEach((product) => {
        const productDTO = {
          id: product.id,
          name: product.productName,
          code: product.productCode,
          imei: product.imei || '',
          price: product.sellingPrice,
        };
        const existingImei = find(this.mainModel.products, {
          imei: product.imei,
        });
        if (existingImei) return;
        this.mainModel.products.push(productDTO);
      });
    },
    debounceSearchImei: debounce(function () {
      this.fetchProductImei();
    }, TIME_TRIGGER),
    onInputProduct(text) {
      this.searchProduct = text.trim() || '';
      if (!this.searchProduct) return;
      this.debounceSearchImei();
    },
    onSelectedProduct(option) {
      const selectedProduct = option.item;
      const existingImei = find(this.mainModel.products, {
        imei: selectedProduct.imeiCode,
      });
      this.searchProduct = '';
      if (existingImei) return;
      const pro = {
        id: selectedProduct.productId,
        name: selectedProduct.productName,
        code: selectedProduct.productCode,
        imei: selectedProduct.imeiCode,
        price: selectedProduct.sellingPrice,
        storeName: selectedProduct.storeName,
      };
      this.mainModel.products.unshift(pro);
    },
    importExcel() {
      this.$bvModal.show('import-priority-cart');
    },
    deleteFromCart(removingItem) {
      this.mainModel.products = reject(this.mainModel.products, (product) => {
        return removingItem.imei == product.imei;
      });
    },
    validate() {
      const result = {
        isValid: true,
        message: '',
      };
      return result;
    },
    save() {
      const validateResult = this.validate();
      if (!validateResult.isValid) return;
      const payload = {
        ...this.mainModel,
        startDate: this.mainModel.startDate
          ? moment(this.mainModel.startDate, 'DD/MM/YYYY').format(
              'YYYY-MM-DD 00:00:00',
            )
          : null,
        endDate: this.mainModel.endDate
          ? moment(this.mainModel.endDate, 'DD/MM/YYYY').format(
              'YYYY-MM-DD 23:59:59',
            )
          : null,
      };
      if (!this.isNew) {
        return;
      }
      this.isNew = false;
      ApiService.post('priority-cart', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            setTimeout(() => {
              this.$router.push({
                path: '/priority-cart',
              });
            }, TIME_TRIGGER);
          } else {
            this.isNew = true;
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.isNew = true;
            makeToastFaile(err.$error);
          } else {
            this.isNew = true;
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .cta-btn {
    font-weight: 600;
    width: 70px;
  }
  .search-product {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .search-product .left {
    width: 49%;
  }
}
</style>